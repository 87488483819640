import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Stat, StatLabel, StatNumber, StatHelpText, Switch, Flex, Box, Container, Text, Center, Heading, Spacer, Button, Image, Input, Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerFooter, Stack, FormLabel, InputGroup, InputLeftAddon, InputRightAddon, Select, Textarea, FormControl} from '@chakra-ui/react';
import { useOutletContext } from "react-router-dom";
import { get, has } from "lodash";

function Settings(props) {
  const [user, setUser] = useOutletContext()
  const [storeData, setStoreData] = useState({})
  console.log('settings bitch', storeData)
  let {location, contact, payment, storeId} = storeData;

  const refreshData = () => {
    const result = fetch(`https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/getAuthStore/${user.username}`)
    .then(response => response.json())
    .then(data => {
        // console.log('in settings array data check', data);
        setStoreData(data[0]);
      }
    ).catch(err => console.log('catch error!!!', err))
  }

  useEffect( () => {
    // console.log('in settinsg', user)
    refreshData()
  }, []);



  return (
    <>
      <Flex flexDir={'column'} p={4}>
        <Box>
          <Heading color={'white'}>Settings</Heading>
        </Box>
        <Box paddingTop={8} p={8}>
          <Flex color={'white'} >
            <Box flex={1}>
              <Box>
                <Text fontSize={'3xl'} fontWeight={'bold'}>Location</Text>
              </Box>
              <Stat paddingTop={6}>
                <StatLabel>Store Display Name</StatLabel>
                <StatNumber>{location && location['displayName'] }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Store Location</StatLabel>
                <StatNumber>{location && location['storeLocation'] }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Address</StatLabel>
                <StatNumber>{location && location['streetAddress'] }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>City</StatLabel>
                <StatNumber>{location && location['city'] }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>State</StatLabel>
                <StatNumber>{location && location['state'] }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Zip Code</StatLabel>
                <StatNumber>{location && location['zipCode'] }</StatNumber>
              </Stat>
            </Box>
            <Box flex={1}>
              <Box>
                <Text fontSize={'3xl'} fontWeight={'bold'}>Contact</Text>
              </Box>
              <Stat paddingTop={6}>
                <StatLabel>Store Code Name</StatLabel>
                <StatNumber>{storeId && storeId }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Phone</StatLabel>
                <StatNumber>{contact && contact['phone'] }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Email</StatLabel>
                <StatNumber>{contact && contact['email'] }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Store Hours of Operation</StatLabel>
                <StatNumber>Sunday { get(storeData, 'contact.hoursOfOperation.sunday') }</StatNumber>
                <StatNumber>Monday { get(storeData, 'contact.hoursOfOperation.monday') }</StatNumber>
                <StatNumber>Tuesday { get(storeData, 'contact.hoursOfOperation.tuesday') }</StatNumber>
                <StatNumber>Wednesday { get(storeData, 'contact.hoursOfOperation.wednesday') }</StatNumber>
                <StatNumber>Thursday { get(storeData, 'contact.hoursOfOperation.thursday') }</StatNumber>
                <StatNumber>Friday { get(storeData, 'contact.hoursOfOperation.friday') }</StatNumber>
                <StatNumber>Saturday { get(storeData, 'contact.hoursOfOperation.saturday') }</StatNumber>
              </Stat>
            </Box>
            <Box flex={1}>
              <Box>
                <Text fontSize={'3xl'} fontWeight={'bold'}>Payment</Text>
              </Box>
              <Stat paddingTop={6}>
                <StatLabel>Tax Location</StatLabel>
                <StatNumber>{ get(storeData, 'payment.taxLocation') }</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Tax Rate</StatLabel>
                <StatNumber>{ get(storeData, 'payment.taxRate')  }%</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Payment Options</StatLabel>
                <Box>
                  <Flex>
                    <Box flex={1}>
                      <Flex flexDir={'column'}>
                        <Stack flex={1} direction={'row'} p={2}>
                          <Switch colorScheme='green' size='lg' isChecked={get(storeData, 'payment.paymentOptions.cash')} />
                          <Text>Cash</Text>
                        </Stack>
                        <Stack flex={1} direction={'row'} p={2}>
                          <Switch colorScheme='green' size='lg' isChecked={get(storeData, 'payment.paymentOptions.zelle')} />
                          <Text>Zelle</Text>
                        </Stack>
                      </Flex>
                    </Box>
                    <Box flex={1}>
                      <Flex flexDir={'column'}>
                        <Stack flex={1} direction={'row'} p={2}>
                          <Switch colorScheme='green' size='lg' isChecked={get(storeData, 'payment.paymentOptions.venmo')} />
                          <Text>Venmo</Text>
                        </Stack>
                        <Stack flex={1} direction={'row'} p={2}>
                          <Switch colorScheme='green' size='lg' isChecked={get(storeData, 'payment.paymentOptions.cashApp')} />
                          <Text>Cash App</Text>
                        </Stack>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Suggested Subject</StatLabel>
                <StatNumber>{get(storeData, 'payment.paymentDestinations.suggestedSubject')}</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Venmo Account</StatLabel>
                <StatNumber>{get(storeData, 'payment.paymentDestinations.venmo')}</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Zelle Account</StatLabel>
                <StatNumber>{get(storeData, 'payment.paymentDestinations.zelle')}</StatNumber>
              </Stat>
              <Stat paddingTop={6}>
                <StatLabel>Cash App Account</StatLabel>
                <StatNumber>{get(storeData, 'payment.paymentDestinations.cashApp')}</StatNumber>
              </Stat>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </>
  );
}

export default Settings;
