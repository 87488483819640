import { useOutletContext } from 'react-router-dom';
import { ChakraProvider, Box, Flex, HStack, Text, Center, Heading, Spacer, Button, Image, Input, Spinner, Icon, ScaleFade} from '@chakra-ui/react'
import { VictoryBar, VictoryChart, VictoryPie, VictoryContainer } from 'victory';
import { FaBeer } from 'react-icons/fa';
import { propTypes } from 'react-bootstrap/esm/Image';

function Dashboard() {
const [user, setUser] = useOutletContext()
console.log('indashbord', user)
const data = [
    {quarter: 1, earnings: 13000},
    {quarter: 2, earnings: 16500},
    {quarter: 3, earnings: 14250},
    {quarter: 4, earnings: 19000}
  ];


  return (
    <>
        <Flex>
            <Box flex={1}>
                <Flex>
                    <Box flex={2}/>
                    <Box flex={8}>
                        <Center  backgroundColor={'rgba(0, 0, 0, 0.4)'} color={'white'} h={16} borderRadius={20} p={5} m={3}>
                            <Text fontSize={'2xl'} fontWeight={'bold'}>
                                Welcome Back, {user.attributes.email}
                            </Text>
                        </Center>
                    </Box>
                    <Box flex={2}/>
                </Flex>
            </Box>
            
        </Flex>
        <Flex>
            <Box flex={1} p={2}>
                <Flex>
                    <Box flex={1} backgroundColor={'rgba(255, 255, 255, 0.3)'} color={'white'} h={320} borderRadius={20} m={1}>
                        <Flex>
                            <Center flex={1} p={2}>
                                <Text fontSize={'2xl'} fontWeight={'bold'}>Average Sales Per Day</Text>
                            </Center>
                        </Flex>
                        <Box h={280}>
                            <VictoryChart>
                                <VictoryBar
                                animate={{
                                    duration: 1000
                                }}
                                colorScale={["tomato", "orange", "gold", "red", "navy" ]}
                                data={data}
                                // data accessor for x values
                                x="quarter"
                                // data accessor for y values
                                y="earnings"/>
                            </VictoryChart> 
                        </Box>
                    </Box>
                    <Box flex={1} backgroundColor={'rgba(255, 255, 255, 0.3)'} color={'white'} h={320} borderRadius={20} m={1}>
                        <Flex>
                            <Center flex={1} p={2}>
                                <Text fontSize={'2xl'} fontWeight={'bold'}>Product Category Breakdown</Text>
                            </Center>
                        </Flex>
                        <Box h={270}>
                            <VictoryPie
                                animate={{
                                    duration: 1000
                                }}
                                innerRadius={75}
                                colorScale={["tomato", "orange", "gold", "red", "navy" ]}
                                data={[
                                    { x: "Flower", y: 45 },
                                    { x: "Concentrates", y: 40 },
                                    { x: "Edibles", y: 35 },
                                    { x: "Topicals", y: 10 },
                                ]}
                            />

                        </Box>
                    </Box>
                    <Box flex={1} backgroundColor={'rgba(255, 255, 255, 0.3)'} color={'white'} h={320} borderRadius={20} m={1}>
                        <Flex>
                            <Center flex={1} p={2}>
                                <Text fontSize={'2xl'} fontWeight={'bold'}>Monthly Sales</Text>
                            </Center>
                        </Flex>
                        <Box h={280}>
                            <VictoryChart>
                                <VictoryBar
                                animate={{
                                    duration: 1000
                                }}
                                data={data}
                                // data accessor for x values
                                x="quarter"
                                // data accessor for y values
                                y="earnings"/>
                            </VictoryChart> 
                        </Box>
                    </Box>
                    
                </Flex>
            </Box>
        </Flex>
    </>
  );
}

export default Dashboard;

{/* <Container>
<Row>
    <Col>
        <main style={{ padding: "1rem 0" }}>
            <h1>Dashboard</h1>
        </main>
        <Alert variant="success">
            <Alert.Heading>Welcome Back, {user.attributes.email}</Alert.Heading>
            <hr />
            <p className="mb-0">
                Whenever you need to, be sure to use margin utilities to keep things nice
                and tidy.
            </p>
        </Alert>
    </Col>
</Row>
<Row>
    <Col>
        <Card style={{ height: '25rem' }}>
            <Card.Body>
                <Card.Title>Average Sales Per Day</Card.Title>
                <Alert variant="success">
                    
                        <Alert.Heading>
                            Trend: Increasing!
                       </Alert.Heading>
                   
                </Alert>
            </Card.Body>
            <div>
                <VictoryChart>
                    <VictoryBar
                    animate={{
                        duration: 1000
                      }}
                    data={data}
                    // data accessor for x values
                    x="quarter"
                    // data accessor for y values
                    y="earnings"/>
                </VictoryChart> 
            </div>
        </Card>
    </Col>
    <Col>
        <Card style={{ height: '25rem' }}>
            <Card.Body>
                <Card.Title>Product Category Breakdown</Card.Title>
            </Card.Body>
            <div style={{padding: "30px"}}>
                    <VictoryPie
                        animate={{
                            duration: 1000
                        }}
                        innerRadius={75}
                      colorScale={["tomato", "orange", "gold", "cyan", "navy" ]}
                      data={[
                        { x: "Flower", y: 45 },
                        { x: "Concentrates", y: 40 },
                        { x: "Edibles", y: 35 },
                        { x: "Topicals", y: 10 },
                      ]}
                   />
            </div>
        </Card>
    </Col>
    <Col>
        <Card style={{ height: '25rem' }}>
            <Card.Body>
                <Card.Title>Sales</Card.Title>
                <Alert variant="danger">
                        <Alert.Heading>
                            Trend: Decreasing!
                       </Alert.Heading>
                </Alert>
            </Card.Body>
            <div>
                <VictoryChart>
                    <VictoryBar
                    animate={{
                        duration: 1000
                      }}
                    data={data}
                    // data accessor for x values
                    x="quarter"
                    // data accessor for y values
                    y="earnings"/>
                </VictoryChart> 
            </div>
        </Card>
    </Col>
</Row>
<Row>
    <Col>
        <VictoryChart>
            <VictoryBar
            data={data}
            // data accessor for x values
            x="quarter"
            // data accessor for y values
            y="earnings"/>
        </VictoryChart>
    </Col>
    <Col>
        <VictoryChart>
            <VictoryBar
            data={data}
            // data accessor for x values
            x="quarter"
            // data accessor for y values
            y="earnings"/>
        </VictoryChart>
    </Col>
</Row>
{/* <Row>
    <Col>
        <VictoryChart>
            <VictoryBar
            data={data}
            // data accessor for x values
            x="quarter"
            // data accessor for y values
            y="earnings"/>
        </VictoryChart>
    </Col>
</Row>
</Container> */}