import './App.css';
import * as V from 'victory';
import { useState, useEffect, useRef } from 'react';
import { Outlet } from "react-router-dom";
import { ChakraProvider, Box, Flex, Divider, Text, Center, Heading, Spacer, Button, Image, Input, Spinner, Icon, ScaleFade} from '@chakra-ui/react'
import {BsCheckCircle, BsPeopleFill} from 'react-icons/bs';
import { GoDashboard, GoGear } from 'react-icons/go';
import { MdOutlineInventory2 } from 'react-icons/md';
import { BiReceipt } from 'react-icons/bi';
import { Link } from "react-router-dom";

import '@aws-amplify/ui-react/styles.css'; // default theme
import { Amplify } from 'aws-amplify';
import {
  AmplifyProvider,
  Authenticator,  
  Theme,
  useAuthenticator,
  CheckboxField
} from '@aws-amplify/ui-react';
import awsExports from './aws-exports';
import Navbar from './components/navbar';
import Dashboard from './routes/dashboard';
import CustomSideNav from './components/sidenav';
Amplify.configure(awsExports);

const MobileImage = `https://flowerly.s3.amazonaws.com/site-images/Flowerly.io-Background-Mobile.webp`;
const LogoImage = `https://flowerly.s3.amazonaws.com/site-images/Flowerly.io-Background-compressed.webp`;
const TransparentImage = `https://flowerly.s3.amazonaws.com/site-images/Flowerly.io-Background-Overlay.webp`;

const mobileStyle = {
  backgroundImage: `url(${MobileImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100vh",
  margin: "auto 0",
  position: "relative"
}

const sectionStyle = {
  backgroundImage: `url(${LogoImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100vh",
  margin: "auto 0",
  position: "relative"
}

const dashboardStyles = {
  backgroundColor: '#DDFAEF',
  backgroundImage: `url(${TransparentImage})`,
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  height: "100vh",
  margin: "auto 0",
  position: "relative"
}

const imageStyles = {
    padding: "40px",
    paddingTop: "6vh",
    textAlign: "center"
}

const theme = {
  name: 'my-theme',
  tokens: {
    colors: {
      font: {
        primary: { value: '#00592A' },
        // ...
      },
    },
  },
};

const components = {
  Header() {
    return (
      <Flex flexDir={'column'} paddingBottom={6} >
        <Center flex={1} textAlign={'center'}>
          <Image
            alt="Amplify logo"
            src='https://flowerly-app-storage.s3.amazonaws.com/logos/3D-Flowerly-Icon-md.webp'
            width="200px"
            height={'200px'}
            
          />
        </Center>
        <Center>
          <Text fontSize={'4xl'} fontWeight={'bold'} color={'white'}>Flowerly AI</Text>
        </Center>
      </Flex>
    );
  },
  Footer() {
    return (
      <Flex>
        <Box flex={1}>
          <Text color={'white'}>All Rights Reserved.</Text>
        </Box>
      </Flex>
    )
  }
  
};

const Nav = ({user, signOut, userLoginState}) => {
  return (
    // <Box bg={"rgba(0,0,0,.5)"} borderRadius={10} padding={props.isMobile ? 1 : 3} margin={3} marginBottom={props.isMobile && -3}>
    <Box borderRadius={10} padding={4} >
      <Flex>
        <Center style={{paddingLeft: "3px"}}>
          <Image src='https://flowerly-app-storage.s3.amazonaws.com/logos/3D-Flowerly-Icon-md.webp' height={'40px'} width={'40px'}/>
        </Center>
        <Center p="1">
          <Heading size='md' color="white">
            flowerly.ai
          </Heading>
        </Center>
        <Spacer />
        <Box m="auto" >
          {signOut ? (
            <Flex>
              <Center backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} p={.4} marginRight={"12px"} paddingRight={"12px"} paddingLeft={"12px"}>
                  <Flex >
                      <GoDashboard style={{color: "#fff", fontSize: '1.2rem', margin: '4px'}}/>
                      <Link to="/dashboard">
                          <Text color={'white'} m={'1px'} fontWeight={'bold'}>Dashboard</Text>
                      </Link>
                  </Flex>
              </Center>
              <Center backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} p={.4} marginRight={"12px"} paddingRight={"12px"} paddingLeft={"12px"}>
                  <Flex >
                      <MdOutlineInventory2 style={{color: "#fff", fontSize: '1.2rem', margin: '4px'}}/>
                      <Link to="/products">
                          <Text color={'white'} m={'1px'} fontWeight={'bold'}>Products</Text>
                      </Link>
                  </Flex>    
              </Center>
              <Center backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} p={.4} marginRight={"12px"} paddingRight={"12px"} paddingLeft={"12px"}>
                  <Flex >
                      <BiReceipt style={{color: "#fff", fontSize: '1.2rem', margin: '4px'}}/>
                      <Link to="/orders">
                          <Text color={'white'} m={'1px'} fontWeight={'bold'}>Orders</Text>
                      </Link>
                  </Flex>
              </Center>
              <Center backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} p={.4} marginRight={"12px"} paddingRight={"12px"} paddingLeft={"12px"}>
                  <Flex >
                      <BsPeopleFill style={{color: "#fff", fontSize: '1.2rem', margin: '4px'}}/>
                      <Link to="/customers">
                          <Text color={'white'} m={'1px'} fontWeight={'bold'}>Customers</Text>
                      </Link>
                  </Flex> 
              </Center>
              <Center backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} p={.4} marginRight={"12px"} paddingRight={"12px"} paddingLeft={"12px"}>
                  <Flex >
                      <GoGear style={{color: "#fff", fontSize: '1.2rem', margin: '4px'}}/>
                      <Link to="/settings">
                          <Text color={'white'} m={'1px'} fontWeight={'bold'}>Settings</Text>
                      </Link>
                  </Flex> 
              </Center>
              <Box backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} p={.4} >
                  <Flex >
                      <Button size="md" fontWeight={'bold'} color={'white'} variant={"ghost"} borderRadius={20} onClick={signOut}>Sign out</Button>
                  </Flex> 
              </Box>
          </Flex>
          ) : (
            <Button size="sm" color={'white'} backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} onClick={userLoginState}>login</Button>
          )}
        </Box>
        {/* <Box style={{margin: "auto", paddingRight: "6px"}} >
          <Button size="sm" color={'white'} backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} >request demo</Button>
        </Box> */}
      </Flex>
    </Box>
  )
}

const SlideOne = () => {
  return (
    <>
        <Text fontSize='6xl' p={10} paddingTop={'10rem'} fontWeight={'bold'}
            bgGradient='linear(to-r, #69e07a, #fff)'
            bgClip='text'>
            Your digital dispensary.
        </Text>
        <Text paddingLeft={10} marginTop={-10} color={'white'} fontSize='xl'> ai budtending, built for the web3 era.</Text>
        <Center>
          <Image w={"100%"} src="https://flowerly.s3.amazonaws.com/site-images/Flowerly-Dispensary-Landing-Page-Jumbo-Nug.webp" paddingTop={'240px'}/>
        </Center>
    </>
  )
}

const SlideOneMobile = () => {
  let browserHeight = window.innerHeight;
  let browserWidth = window.innerWidth;
  return (
    <>
        {browserWidth > 420 ? (
          <>
            <Text fontSize='6xl' p={10} paddingTop={browserHeight/6} fontWeight={'bold'}
                bgGradient='linear(to-r, #69e07a, #fff)'
                bgClip='text'>
                Your digital dispensary.
            </Text>
            <Text paddingLeft={10} marginTop={-10} color={'white'} fontSize='2xl'> ai budtending, built for the web3 era.</Text>
            <Center>
              <Image w={"100%"} src="https://flowerly.s3.amazonaws.com/site-images/Flowerly-Dispensary-Landing-Page-Jumbo-Nug-Mobile.webp" paddingTop={browserHeight/4}/>
            </Center>
          </>
        ) : (
          <>
            <Center>
              <Text fontSize='5xl' p={10} paddingTop={browserHeight/6} fontWeight={'bold'}
                  bgGradient='linear(to-r, #69e07a, #fff)'
                  bgClip='text'>
                  Your digital dispensary.
              </Text>
            </Center>
            <Text paddingLeft={10} marginTop={-10} color={'white'} fontSize='lg'> ai budtending, built for the web3 era.</Text>
            <Center>
              <Image w={"100%"} src="https://flowerly.s3.amazonaws.com/site-images/Flowerly-Dispensary-Landing-Page-Jumbo-Nug-Mobile.webp" paddingTop={browserHeight < 700 ? browserHeight/4 : browserHeight/3}/>
            </Center>
          </>
        )}

   
    </>
  )
}

const SlideTwo = () => {
  return (
    <>
      <Flex>
          <Box flex={1} color={'white'}>
              <Flex paddingTop={20}>
                <Box flex={1} />
                <Box flex={2}>
                  <Center>
                    <Text fontSize={'2xl'} fontWeight={'bold'} p={4}>
                      Search Summary
                    </Text>
                  </Center>
                  <Box backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} p={4}>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={8} >
                          <Text size={'2xl'} fontWeight={'bold'} p={2}>
                          looking for something
                          </Text>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={10} >
                          <Box backgroundColor={'rgba(26, 26, 26, .50)'} borderRadius={20} p={3} paddingLeft={6} paddingRight={6} w={'100%'}>
                            <Text size={'2xl'} fontWeight={'bold'} textAlign={'center'}>
                            creative and uplifting 
                            </Text>
                          </Box>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={8} >
                          <Text size={'2xl'} fontWeight={'bold'} p={2}>
                          that has the aroma of
                          </Text>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={10} >
                          <Box backgroundColor={'rgba(26, 26, 26, .50)'} borderRadius={20} p={3} paddingLeft={6} paddingRight={6} w={'100%'}>
                            <Text size={'2xl'} fontWeight={'bold'} textAlign={'center'}>
                            lemon, citrus
                            </Text>
                          </Box>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={8} >
                          <Text size={'2xl'} fontWeight={'bold'} p={2}>
                          with plans to crush
                          </Text>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={10} >
                          <Box backgroundColor={'rgba(26, 26, 26, .50)'} borderRadius={20} p={3} paddingLeft={6} paddingRight={6} w={'100%'}>
                            <Text size={'2xl'} fontWeight={'bold'} textAlign={'center'}>
                            work and a workout
                            </Text>
                          </Box>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={8} >
                          <Text size={'2xl'} fontWeight={'bold'} p={2}>
                          in this category
                          </Text>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={10} >
                          <Box backgroundColor={'rgba(26, 26, 26, .50)'} borderRadius={20} p={3} paddingLeft={6} paddingRight={6} w={'100%'} marginBottom={6}>
                            <Text size={'2xl'} fontWeight={'bold'} textAlign={'center'}>
                            flower
                            </Text>
                          </Box>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                  </Box>
                </Box>
                <Box flex={1} />
              </Flex>
          </Box>
          <Box flex={1}>
            <Flex>
              
              <Box flex={6}>
                <Box paddingTop={'12rem'} color={'white'}>
                  <Text fontSize={'4xl'} fontWeight={'bold'} >
                  better search tools
                  </Text>
                  <Text fontSize={'4xl'} fontWeight={'medium'} >
                  designed for your customers
                  </Text>
                  <Text paddingTop={6}>
                  whether your customer knows what they’re looking for or needs help, flowerly’s ehanced search tool helps customer hone their goals and understands their preferences before they start browsing, like a human.
                  </Text>
                </Box>
              </Box>
              <Box flex={1}/>
            </Flex>
          </Box>
        </Flex>
    </>
  )
}

const SlideTwoMobile = () => {
  return (
    <>
      <Flex flexDir={'column'}>
          <Box flex={1} color={'white'}>
              <Flex paddingTop={20}>
               
                <Box flex={1} p={4}>
                  <Center>
                    <Text fontSize={'2xl'} fontWeight={'bold'} p={4}>
                      Search Summary
                    </Text>
                  </Center>
                  <Box backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20} p={4}>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={8} >
                          <Text size={'2xl'} fontWeight={'bold'} p={2}>
                          looking for something
                          </Text>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={10} >
                          <Box backgroundColor={'rgba(26, 26, 26, .50)'} borderRadius={20} p={3} paddingLeft={6} paddingRight={6} w={'100%'}>
                            <Text size={'2xl'} fontWeight={'bold'} textAlign={'center'}>
                            creative and uplifting 
                            </Text>
                          </Box>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={8} >
                          <Text size={'2xl'} fontWeight={'bold'} p={2}>
                          that has the aroma of
                          </Text>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={10} >
                          <Box backgroundColor={'rgba(26, 26, 26, .50)'} borderRadius={20} p={3} paddingLeft={6} paddingRight={6} w={'100%'}>
                            <Text size={'2xl'} fontWeight={'bold'} textAlign={'center'}>
                            lemon, citrus
                            </Text>
                          </Box>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={8} >
                          <Text size={'2xl'} fontWeight={'bold'} p={2}>
                          with plans to crush
                          </Text>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={10} >
                          <Box backgroundColor={'rgba(26, 26, 26, .50)'} borderRadius={20} p={3} paddingLeft={6} paddingRight={6} w={'100%'}>
                            <Text size={'2xl'} fontWeight={'bold'} textAlign={'center'}>
                            work and a workout
                            </Text>
                          </Box>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={8} >
                          <Text size={'2xl'} fontWeight={'bold'} p={2}>
                          in this category
                          </Text>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                      <Flex>
                        <Box flex={1} />
                        <Center flex={10} >
                          <Box backgroundColor={'rgba(26, 26, 26, .50)'} borderRadius={20} p={3} paddingLeft={6} paddingRight={6} w={'100%'} marginBottom={6}>
                            <Text size={'2xl'} fontWeight={'bold'} textAlign={'center'}>
                            flower
                            </Text>
                          </Box>
                        </Center>
                        <Box flex={1} />
                      </Flex>
                  </Box>
                </Box>
           
              </Flex>
          </Box>
          <Box flex={1}>
            <Flex>
              <Box p={6} >
                <Box color={'white'}>
                  <Text fontSize={'3xl'} fontWeight={'bold'} >
                  better search tools
                  </Text>
                  <Text fontSize={'xl'} fontWeight={'semibold'} >
                  designed for your customers
                  </Text>
                  <Text paddingTop={6}>
                  whether your customer knows what they’re looking for or needs help, flowerly’s ehanced search tool helps customer hone their goals and understands their preferences before they start browsing, like a human.
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
    </>
  )
}

const SlideThree = () => {
  return (
    <>
      <Flex paddingTop={20}>
          <Box flex={1}>
            <Flex>
              <Box flex={1}/>
              <Center flex={6} p={4}>
                  <Box paddingTop={'12rem'} color={'#1B1464'}>
                    <Text fontSize={'4xl'} fontWeight={'bold'} >
                    happier customers are
                    </Text>
                    <Text fontSize={'4xl'} fontWeight={'medium'} >
                    loyal customers
                    </Text>
                    <Text paddingTop={6}>   
                    empower your customers with personalized recommendations, data that is automatically adjusted to their level of experience to help them make informed buying decisions. 
                    </Text>
                  </Box>
              </Center>
              <Box flex={1}/>

            </Flex>
          </Box>
          <Box flex={1}>
            <Center>
              <Image boxSize='600px' fit={'contain'} src={'https://flowerly.s3.amazonaws.com/site-images/Flowerly-Dispensary-smart-recommendations.webp'} p={12}/>
            </Center>
          </Box>
        </Flex>
    </>
  )
}
 
const SlideThreeMobile = () => {
  return (
    <>
      
          <Box >
            <Flex flexDir={'column'}>
              <Box flex={1}>
                <Center>
                  <Image boxSize={'100vw'} fit={'contain'} src={'https://flowerly.s3.amazonaws.com/site-images/Flowerly-Dispensary-smart-recommendations.webp'} p={4}/>
                </Center>
              </Box>
              <Box flex={1}/>
                <Center p={6}>
                    <Box color={'#1B1464'}>
                      <Text fontSize={'3xl'} fontWeight={'bold'} >
                      happier customers are
                      </Text>
                      <Text fontSize={'3xl'} fontWeight={'medium'} >
                      loyal customers
                      </Text>
                      <Text paddingTop={6}>   
                      empower your customers with personalized recommendations, data that is automatically adjusted to their level of experience to help them make informed buying decisions. 
                      </Text>
                    </Box>
                </Center>
              <Box />

            </Flex>
          </Box>
        
    </>
  )
}

const SlideFour = () => {
  return (
    <>
      <Flex flexDir={'column'}>
          <Box flex={1}>
            <Flex>
              <Box flex={1}/>
              <Box flex={6} p={4}>
                  <Center paddingTop={'12rem'} color={'#1B1464'}>
                    <Text fontSize={'5xl'} fontWeight={'bold'} >
                    one-stop shop &nbsp;
                    </Text>
                    <Text fontSize={'5xl'} fontWeight={'medium'} >
                    to help you bloom faster
                    </Text>
                  </Center>  
                  <Center>
                    <Text fontSize={'3xl'} fontWeight={'light'} >
                      all the tools you need, to help you focus on growing your business
                    </Text>       
                  </Center>   
              </Box>
              <Box flex={1}/>
            </Flex>
          </Box>
          <Box flex={1}>
            <Flex paddingTop={12}>
              <Box flex={1}/>
              <Box flex={5} p={4}>
                  <Text color={'white'} fontSize={'2xl'} fontWeight={'bold'}>
                    higher quality data
                  </Text>
                  <Text color={'white'} fontSize={'lg'} fontWeight={'light'}>
                    flowerly ensures all your products have up-to-date metadata so your customers can trust that they are ordering exactly what they had in mind
                  </Text>
              </Box>
              <Box flex={5}  p={4}>
                  <Text color={'white'} fontSize={'2xl'} fontWeight={'bold'}>
                  all types of commerce
                  </Text>
                  <Text color={'white'} fontSize={'lg'} fontWeight={'light'}>
                  flowerly supports all types of browsing and ordering including in-store pickup, curbside pickup, delivery, instore menu’s, kiosks, and drive-thru ordering
                  </Text>
              </Box>
              <Box flex={5}  p={4}>
                  <Text color={'white'} fontSize={'2xl'} fontWeight={'bold'}>
                    integrations in sync
                  </Text>
                  <Text color={'white'} fontSize={'lg'} fontWeight={'light'}>
                  with automatic real-time POS sync, flowerly integrates with all major POS providers and inventory management systems, so you’ll always have accurate, reliable data
                  </Text>
              </Box>
              <Box flex={1}/>
            </Flex>
          </Box>
        </Flex>
    </>
  )
}

const SlideFourMobile = () => {
  return (
    <>
      <Flex flexDir={'column'}>
          <Box flex={1} paddingTop={6}> 
            <Flex>
              <Box flex={1}/>
              <Box flex={10} className='gradient-background' p={4} borderRadius={20}>
                  <Text paddingTop={1} textAlign={'center'} color={'white'} fontSize='4xl'>Let’s find the <br/> 
                  <a style={{fontWeight: "800"}}> perfect cannabis </a>
                  <br/>
                  for you
                  </Text>
                  <Center p={4}>
                    <Button variant={'outline'} color={'white'}><a href={'https://www.flowerly.ai'}>Demo</a></Button>
                  </Center>
              </Box>
              <Box flex={1}/>

            </Flex>
          </Box>
          <Box flex={1}>
            <Flex flexDir={'column'} color={'#1B1464'}>
              <Box p={6} >
                  <Box >
                    <Text fontSize={'3xl'} fontWeight={'bold'} >
                    one-stop shop &nbsp;
                    </Text>
                  </Box>  
                  <Box >
                    <Text fontSize={'3xl'} fontWeight={'medium'} >
                    to help you bloom faster
                    </Text>
                  </Box>
              </Box>
              <Box paddingRight={6} paddingLeft={6}  >
                  <Box >
                    <Text fontSize={'xl'} fontWeight={'light'} >
                      all the tools you need, to help you focus on growing your business
                    </Text>       
                  </Box>   
              </Box>
            
            </Flex>
          </Box>
          <Box flex={1}>
            <Flex paddingTop={12} flexDir={'column'}>
              
              <Box flex={5} p={6}>
                  <Text color={'white'} fontSize={'2xl'} fontWeight={'bold'}>
                    higher quality data
                  </Text>
                  <Text color={'white'} fontSize={'lg'} fontWeight={'light'}>
                    flowerly ensures all your products have up-to-date metadata so your customers can trust that they are ordering exactly what they had in mind
                  </Text>
              </Box>
              <Box flex={5}  p={6}>
                  <Text color={'white'} fontSize={'2xl'} fontWeight={'bold'}>
                  all types of commerce
                  </Text>
                  <Text color={'white'} fontSize={'lg'} fontWeight={'light'}>
                  flowerly supports all types of browsing and ordering including in-store pickup, curbside pickup, delivery, instore menu’s, kiosks, and drive-thru ordering
                  </Text>
              </Box>
              <Box flex={5}  p={6}>
                  <Text color={'white'} fontSize={'2xl'} fontWeight={'bold'}>
                    integrations in sync
                  </Text>
                  <Text color={'white'} fontSize={'lg'} fontWeight={'light'}>
                  with automatic real-time POS sync, flowerly integrates with all major POS providers and inventory management systems, so you’ll always have accurate, reliable data
                  </Text>
              </Box>
            </Flex>
          </Box>
        </Flex>
    </>
  )
}
 
const SlideFiveCTA = () => {
  let [companyDemoInfo, setCompanyDemoInfo] = useState({storeName: '', nameOfContact: '', email: '', phone: '', zipCode: ''})
  let [formStatus, setFormStatus] = useState('empty');

  let handleFormStatus = (newStatus) => {
    setFormStatus(newStatus);
  }

  let handleCompanyDemoInfo = (e, value) => {
    console.log(value, e.target.value)
    let newValue = {}
    newValue[value] = e.target.value
    setCompanyDemoInfo(companyDemoInfo => ({
      ...companyDemoInfo,
      ...newValue
    }))
  }

  let basicValidation = () => {
    let {storeName, nameOfContact, email, phone, zipCode } = companyDemoInfo;
    return zipCode === "" || phone === "" || email === "" || nameOfContact === "" || storeName === "" ? true : false;
  }

  let saveDataViaFlowerlyAPI = () => {
    handleFormStatus('loading')
    let {storeName, nameOfContact, email, phone, zipCode } = companyDemoInfo;
    const data = { 'body': {
      storeName, nameOfContact, email, phone, zipCode
    } };

    fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/createNewProspectiveCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      handleFormStatus('success')
    })
    .catch((error) => {
      console.error('Error:', error);
      handleFormStatus('error')
    });
  }

  const FormSavingInProgress = () => {
    return (
      <>
        <Flex flexDir={'column'}>
          <Box>
            <Center p={4}>
              <Text fontSize={'large'} color={'white'}>This is the beginning of something great</Text>
            </Center>
          </Box>
          <Center p={12}>
            <Spinner
              thickness='6px'
              speed='0.65s'
              emptyColor='gray.200'
              color='green.500'
              size='xl'
              />
          </Center>
          <Box>
            <Center p={4}>
              <Text fontSize={'large'} color={'white'}>Sending your Contact Information</Text>
            </Center>
          </Box>
        </Flex>
      </>
    )
  }

  const FormSuccessfullySaved = () => {
    return (
      <>
        <Flex flexDir={'column'}>
          <Box>
            <Center p={4}>
              <Text fontSize={'large'} color={'white'}>Sent! We'll be in touch soon.</Text>
            </Center>
          </Box>
          <Center p={6}>
            <ScaleFade initialScale={0.9} in={true}>
              <Icon color={'green.300'} as={BsCheckCircle} fontSize={'8xl'}/>
            </ScaleFade>
          </Center>
          <Box>
            <Center p={4}>
              <Text fontSize={'large'} color={'white'}>Looking forward to chatting soon.</Text>
            </Center>
          </Box>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Flex paddingTop={'10rem'} paddingBottom={6}>
          <Box flex={1}/>
          <Box flex={5} p={12} paddingTop={'10rem'} >
            <Text color={'white'} fontSize={'6xl'} fontWeight={'medium'}>
            get started today
            </Text>
            <Text color={'white'} fontSize={'2xl'} fontWeight={'light'}>
            Ready to chat? Reach out to schedule a consultation, customized to your dispensary.
            </Text>
          </Box>
          <Box flex={5} p={12}>
            <Center>
              <Text p={6} fontSize={'2xl'} color={'white'} fontWeight={'medium'}>
                request demo
              </Text>
            </Center>
            <Center>
              <Box backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20}  p={4}>
                { formStatus === 'empty' && 
                  <>
                    <Box>
                      <Box p={4}>
                        <Input color={'white'} variant='outline' opacity={50} placeholder='store name' onChange={(e) => {handleCompanyDemoInfo(e, 'storeName')}} />
                      </Box>
                      <Box p={4}>
                        <Input color={'white'} variant='outline' opacity={50} placeholder='name of contact' onChange={(e) => {handleCompanyDemoInfo(e, 'nameOfContact')}}/>
                      </Box>
                      <Box p={4}>
                        <Input color={'white'} variant='outline' opacity={50} placeholder='email' onChange={(e) => {handleCompanyDemoInfo(e, 'email')}}/>
                      </Box>
                      <Flex >
                        <Box flex={1} p={4}>
                          <Input color={'white'} variant='outline' opacity={50} placeholder='phone' type='tel' onChange={(e) => {handleCompanyDemoInfo(e, 'phone')}}/>
                        </Box>
                        <Box flex={1} p={4}>
                          <Input color={'white'} variant='outline' opacity={50} placeholder='zip code' onChange={(e) => {handleCompanyDemoInfo(e, 'zipCode')}}/>
                        </Box>
                      </Flex>
                      <Box p={4}>
                        <Button variant={'solid'} isFullWidth opacity={50} placeholder='store name' disabled={basicValidation()} onClick={() => { saveDataViaFlowerlyAPI() }}>submit</Button>
                      </Box>
                    </Box>
                  </>
                }
                { formStatus === 'loading' && <FormSavingInProgress />}
                { formStatus === 'success' && <FormSuccessfullySaved />}
              </Box>
            </Center>
          </Box>        
          <Box flex={1}/>
        </Flex>
    </>
  )
}

const SlideFiveCTAMobile = () => {
  let [companyDemoInfo, setCompanyDemoInfo] = useState({storeName: '', nameOfContact: '', email: '', phone: '', zipCode: ''})
  let [formStatus, setFormStatus] = useState('empty');

  let handleFormStatus = (newStatus) => {
    setFormStatus(newStatus);
  }

  let handleCompanyDemoInfo = (e, value) => {
    console.log(value, e.target.value)
    let newValue = {}
    newValue[value] = e.target.value
    setCompanyDemoInfo(companyDemoInfo => ({
      ...companyDemoInfo,
      ...newValue
    }))
  }

  let basicValidation = () => {
    let {storeName, nameOfContact, email, phone, zipCode } = companyDemoInfo;
    return zipCode === "" || phone === "" || email === "" || nameOfContact === "" || storeName === "" ? true : false;
  }

  let saveDataViaFlowerlyAPI = () => {
    handleFormStatus('loading')
    let {storeName, nameOfContact, email, phone, zipCode } = companyDemoInfo;
    const data = { 'body': {
      storeName, nameOfContact, email, phone, zipCode
    } };

    fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/createNewProspectiveCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
      handleFormStatus('success')
    })
    .catch((error) => {
      console.error('Error:', error);
      handleFormStatus('error')
    });
  }

  const FormSavingInProgress = () => {
    return (
      <>
        <Flex flexDir={'column'}>
          <Box>
            <Center p={4}>
              <Text fontSize={'large'} color={'white'}>This is the beginning of something great</Text>
            </Center>
          </Box>
          <Center p={12}>
            <Spinner
              thickness='6px'
              speed='0.65s'
              emptyColor='gray.200'
              color='green.500'
              size='xl'
              />
          </Center>
          <Box>
            <Center p={4}>
              <Text fontSize={'large'} color={'white'}>Sending your Contact Information</Text>
            </Center>
          </Box>
        </Flex>
      </>
    )
  }

  const FormSuccessfullySaved = () => {
    return (
      <>
        <Flex flexDir={'column'}>
          <Box>
            <Center p={4}>
              <Text fontSize={'large'} color={'white'}>Sent! We'll be in touch soon.</Text>
            </Center>
          </Box>
          <Center p={6}>
            <ScaleFade initialScale={0.9} in={true}>
              <Icon color={'green.300'} as={BsCheckCircle} fontSize={'8xl'}/>
            </ScaleFade>
          </Center>
          <Box>
            <Center p={4}>
              <Text fontSize={'large'} color={'white'}>Looking forward to chatting soon.</Text>
            </Center>
          </Box>
        </Flex>
      </>
    )
  }

  return (
    <>
      <Flex flexDir={'column'} >
          <Box flex={5} p={6} >
            <Text bgGradient='linear(to-r, #69e07a, #fff)' bgClip='text' fontSize={'4xl'} fontWeight={'bold'}>
            get started today
            </Text>
            <Text bgGradient='linear(to-r, #69e07a, #fff)' bgClip='text' fontSize={'xl'} fontWeight={'medium'}>
            Ready to chat? Reach out to schedule a consultation, customized to your dispensary.
            </Text>
          </Box>
          <Box flex={5} p={4}>
            <Center>
              <Text p={2} fontSize={'2xl'} color={'white'} fontWeight={'medium'}>
                request live demo
              </Text>
            </Center>
            <Center>
              <Box backgroundColor={'rgba(255, 255, 255, .20)'} borderRadius={20}  p={2}>
                { formStatus === 'empty' && 
                  <>
                    <Box>
                      <Box p={4}>
                        <Input color={'white'} variant='outline' opacity={50} placeholder='store name' onChange={(e) => {handleCompanyDemoInfo(e, 'storeName')}} />
                      </Box>
                      <Box p={4}>
                        <Input color={'white'} variant='outline' opacity={50} placeholder='name of contact' onChange={(e) => {handleCompanyDemoInfo(e, 'nameOfContact')}}/>
                      </Box>
                      <Box p={4}>
                        <Input color={'white'} variant='outline' opacity={50} placeholder='email' onChange={(e) => {handleCompanyDemoInfo(e, 'email')}}/>
                      </Box>
                      <Flex >
                        <Box flex={1} p={4}>
                          <Input color={'white'} variant='outline' opacity={50} placeholder='phone' type='tel' onChange={(e) => {handleCompanyDemoInfo(e, 'phone')}}/>
                        </Box>
                        <Box flex={1} p={4}>
                          <Input color={'white'} variant='outline' opacity={50} placeholder='zip code' onChange={(e) => {handleCompanyDemoInfo(e, 'zipCode')}}/>
                        </Box>
                      </Flex>
                      <Box p={4}>
                        <Button variant={'solid'} isFullWidth opacity={50} placeholder='store name' disabled={basicValidation()} onClick={() => { saveDataViaFlowerlyAPI() }}>submit</Button>
                      </Box>
                    </Box>
                  </>
                }
                { formStatus === 'loading' && <FormSavingInProgress />}
                { formStatus === 'success' && <FormSuccessfullySaved />}
              </Box>
            </Center>
          </Box>        
         
        </Flex>
    </>
  )
}

const Footer = () => {
  return (
      <Center backgroundColor={'black'} h={300}>
        <Flex p={12}>
          <Center style={{paddingLeft: "3px"}}>
            <Image src='https://flowerly-app-storage.s3.amazonaws.com/logos/3D-Flowerly-Icon-md.webp' height={'40px'} width={'40px'}/>
          </Center>
          <Center p="1">
            <Heading size='md' color="white">
              flowerly.ai
            </Heading>
          </Center>
          <Spacer />
          <Box m={'auto'} p={12} >
            <Button variant='link' size={'md'} color={'white'} fontWeight={'medium'}>privacy policy</Button>
          </Box>
          <Box m={'auto'} p={12} >
          <Button variant='link' size={'md'} color={'white'} fontWeight={'medium'}>terms of use</Button>
          </Box>
          <Box m={'auto'} p={12} color={'white'} fontWeight={'medium'}>
            Copyright © 2022 by Flowerly AI Inc.
          </Box>
          <Box m={'auto'} p={12} color={'white'} fontWeight={'medium'}>
            <Flex flexDir={'column'}> 
              <Text>built in loving memory of</Text>
              <Center>
                <Image src={"https://flowerly.s3.amazonaws.com/site-images/Mom's-Signature.webp"} w={192}/>
              </Center>

            </Flex>
          </Box>
        </Flex>
      </Center>
  )
}

const FooterMobile = () => {
  return (
    <Center backgroundColor={'black'} h={292}>
      <Flex p={2} flexDirection={'column'}>
        <Center>
          <Flex>
            <Box >
              <Image src='https://flowerly-app-storage.s3.amazonaws.com/logos/3D-Flowerly-Icon-md.webp' height={'30px'} width={'30px'}/>
            </Box>
            <Center p="1">
              <Heading size='md' color="white">
                flowerly.ai
              </Heading>
            </Center>
          </Flex>
        </Center>
        <Flex>
          <Box m={'auto'} p={4} >
            <Button variant='link' size={'md'} color={'white'} fontWeight={'medium'}>privacy policy</Button>
          </Box>
          <Box m={'auto'} p={4} >
          <Button variant='link' size={'md'} color={'white'} fontWeight={'medium'}>terms of use</Button>
          </Box>
        </Flex>
       
        <Box m={'auto'} p={4} color={'white'} fontWeight={'medium'}>
          <Flex flexDir={'column'}> 
            <Text>built in loving memory of</Text>
            <Center>
              <Image src={"https://flowerly.s3.amazonaws.com/site-images/Mom's-Signature.webp"} w={192}/>
            </Center>

          </Flex>
        </Box> 
        <Center>
          <Box m={'auto'} p={4} color={'white'} fontWeight={'medium'}>
            Copyright © 2022 by Flowerly AI Inc.
          </Box>
        </Center>
      </Flex>
    </Center>
)
}

const LoggedOutView = (userLoginState, isMobile) => {
  let browserHeight = window.innerHeight;
  console.log('test', browserHeight)

  return (
    <>
      {isMobile ? (
        <>
          <Box
            w='100%'
            h={'4200'}
            bgGradient={[
              // 'linear(to-tr, teal.300, yellow.400)',
              'linear(to-b, #1B1464, #1B1464, #22B373, #fff, #1B1464, #1B1464)',
              // 'linear(to-b, orange.100, purple.300)',
            ]}
          >
            <Nav userLoginState={userLoginState}/>
            <SlideOneMobile />
            <SlideTwoMobile />
            <SlideThreeMobile />
            <SlideFourMobile />
            <SlideFiveCTAMobile />
          </Box>
          <FooterMobile />
        </>
      ) : (
        <>
          <Box
            w='100%'
            h={'3600'}
            bgGradient={[
              // 'linear(to-tr, teal.300, yellow.400)',
              'linear(to-b, #1B1464, #1B1464, #22B373, #fff, #1B1464, #1B1464)',
              // 'linear(to-b, orange.100, purple.300)',
            ]}
          >
            <Nav userLoginState={userLoginState}/>
            <SlideOne />
            <SlideTwo />
            <SlideThree />
            <SlideFour />
            <SlideFiveCTA />
          </Box>
          <Footer />
        </>
      )}
      
    </>
  )
}  
  

const AuthView = (user, setUser, userLoginState) => {
  return (
   
        <Box bgGradient={'linear(blue.600, purple.500, green.400)'} h={'100vh'}>
          <Authenticator hideSignUp={true} components={components} theme={theme} socialProviders={['google']} >
            {({ signOut, user }) => {
              setUser(user);
              console.log("user set", user)
              console.log("user id", user.username)
              return (     
                <>
                    <Nav user={user} signOut={signOut}/>
                    <Flex>
                      {/* <Box flex={1}>
                        <CustomSideNav/>
                      </Box> */}
                      <Box flex={1}>
                        <Outlet context={[user, setUser]} user={user} />
                      </Box>
                    </Flex>
                    {/* <Button  variant="outline" onClick={userLoginState}>Go Back</Button> */}
                </>
              )}
            }
          </Authenticator>
        </Box>
    
  )
}
  


function App() {
  const [user, setUser] = useState(null);
  const [toggleLogin, setToggleLogin] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const myFunction = (awsUser) => {
    setUser(awsUser);
  }
  const userLoginState = () => { setToggleLogin(!toggleLogin) }

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  const isMobile = width <= 768;
  return (
      <AmplifyProvider >
        <ChakraProvider>
          {toggleLogin ? AuthView(user, setUser, userLoginState) : LoggedOutView(userLoginState, isMobile) }
        </ChakraProvider>
      </AmplifyProvider>
  );
}


export default App;


{/* <Row>
<Col>
  <h1 style={{textAlign: "center", fontSize: "4vw", fontFamily: 'Chicle, cursive', color: "#D9E8D1", WebkitTextStrokeWidth: ".05vw", WebkitTextStrokeColor: "#00592A"}}>Flowerly</h1>
  <div className="text_shadows" style={{textAlign: "center", fontSize: "6vw", fontFamily: 'DM Serif Text, serif', color: "#00592A"}}>Coming Soon
  </div>
</Col>

</Row> */}

// <AmplifyProvider >
// {toggleLogin ? (
//     <div style={dashboardStyles}>
//       <Container  fluid>
//           <Authenticator components={components}>
//             {({ signOut, user }) => {
//               setUser(user);
//               console.log("user set", user)
//               return (     
//                 <Row>
//                   <Navbar user={user} signOut={<Button size='sm' variant="outline-light" onClick={signOut}>Sign out</Button>}/>
//                   <Col xs={1} sm={2} className="YO MOFO" style={{backgroundColor: "#5F7A61"}}>
//                     <CustomSideNav/>
//                   </Col>
//                   <Col xs={10} >
                  
//                     <Row>
//                         {/* <Dashboard user={user}/> */}
//                         <Outlet context={[user, setUser]}/>
//                         <Col style={{textAlign: "center", paddingTop: "20px"}}>
//                               <Button  variant="outline-dark" style={{backgroundColor: "FEEDC1"}} onClick={userLoginState}>Go Back</Button>
//                         </Col>
//                     </Row>
//                   </Col>
//                   <Col xs={1}>
//                     <div ></div>
//                   </Col>
//                 </Row>
//               )}
//               }
//           </Authenticator>
//       </Container>
//     </div>
// ) : (
//     <Container style={sectionStyle} fluid>
//         <Row style={{paddingTop: "20vh"}}>
//           <Col>
//             <h1 style={{textAlign: "center", fontSize: "14vw", fontFamily: 'Chicle, cursive', color: "#D9E8D1", WebkitTextStrokeWidth: ".15vw", WebkitTextStrokeColor: "#00592A"}}>Flowerly</h1>
//             {/* <div className="text_shadows" style={{textAlign: "center", fontSize: "6vw", fontFamily: 'DM Serif Text, serif', color: "#00592A"}}>Coming Soon
//             </div> */}
//           </Col>
//         </Row>
//         <Row>
//           <Col style={{textAlign: "center"}}>
//             <Button size="lg" variant="outline-dark" style={{backgroundColor: "FEEDC1"}} onClick={userLoginState}>Get Started</Button>
//           </Col>
//         </Row>
//     </Container>
//   )}
// </AmplifyProvider>