import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Dashboard from './routes/dashboard';
import Products from './routes/products';
import Orders from './routes/orders';
import Customers from './routes/customers';
import Settings from './routes/settings';


const rootElement = document.getElementById("root");


ReactDOM.render(
 
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<Dashboard />}/>
          <Route path="/dashboard" element={<Dashboard />}/>
          <Route path="/products" element={<Products />}/>
          <Route path="/orders" element={<Orders />}/>
          <Route path="/customers" element={<Customers />}/>
          <Route path="/settings" element={<Settings />}/>
        </Route>
      </Routes>
    </BrowserRouter>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// ReactDOM.render(
 
//   <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<App />}>
//         <Route path="/" element={<Dashboard />}/>
//         <Route path="/dashboard" element={<Dashboard />}/>
//         <Route path="/products" element={<Products />}/>
//         <Route path="/orders" element={<Orders />}/>
//         <Route path="/customers" element={<Customers />}/>
//         <Route path="/settings" element={<Settings />}/>
//       </Route>
//     </Routes>
//   </BrowserRouter>,
// rootElement
// );