import { Container, Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';


function customNav(props) {
    // console.log("navbar", props.user.attributes.email)
    return (
        <Navbar bg="dark" variant="dark" sticky="top" expand="lg">
            {/* <Navbar.Brand href="/"><h1 style={{fontSize: "2em", fontFamily: 'Chicle, cursive', color: "#D9E8D1", WebkitTextStrokeWidth: ".05vw", WebkitTextStrokeColor: "#00592A"}}>Flowerly</h1></Navbar.Brand> */}
            <Navbar.Brand href="/">
                <Image width={"120px"} src="https://flowerly.s3.amazonaws.com/site-images/Logo-OneLine-Small.webp"/>
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
                {/* <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                <Nav.Link href="link">Link</Nav.Link>
                <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                </NavDropdown> */}
            </Nav>
            <Nav>
                
                <Nav.Link eventKey={2} href="settings">
                    {props.user && props.user.attributes.email}
                </Nav.Link>
                <Nav.Link eventKey={3} >
                    {props.signOut}
                </Nav.Link>
            </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}


export default customNav;