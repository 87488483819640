import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import {OBJModel} from 'react-3d-viewer'

import { useDisclosure, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Tfoot, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, useToast, Flex, Box, Container, Text, Center, Heading, Spacer, Button, Image, Input, Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerFooter, Stack, FormLabel, InputGroup, InputLeftAddon, InputRightAddon, Select, Textarea, FormControl, HStack} from '@chakra-ui/react';


import get from 'lodash/get'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const updateProductInDatabase = (data) => {
  // const data = {
  //     "productId": "2d832159-58a3",
  //     "storeId": "12019922",
  //     "updatedKey": "brand",
  //     "updatedValue": "1-11"
  // };

  fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/updateProduct', {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({body: data}),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Success:', data);
  })
  .catch((error) => {
    console.error('Error:', error);
  });
}



const Orders = function() {
  const [viewingMode, setViewingMode] = useState('kiosk')
  const gridRef = useRef();
    const [rowData, setInventory] = useState([])
    const [columnDefs] = useState([
        { field: "orderId", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})} },
        { field: "storeId", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "productName", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "productType", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "pricePerUnit", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "quantity", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "strainType", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "units", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
    ]);  
    //  updateProduct({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})

    const handleViewingModeChange = () => {
      viewingMode === 'kiosk' ? (setViewingMode('overview')) : (setViewingMode('kiosk'))
    }

    const refreshData = () => {
      const result = fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/getOrders')
      .then(response => response.json())
      .then(data => {
          // console.log('in fetchInventory array data check', Array.isArray(data));
          setInventory(data);
        }
      ).catch(err => console.log('catch error!!!', err))
    }

    useEffect( () => {
      refreshData()
    }, []);
  
    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current.api.setQuickFilter(
        document.getElementById('filter-text-box').value
      );
    }, []);
    
    const fakeArray = [1,2,3,4,5, 6, 7, 8]

    console.log("orders", rowData)

    const ClassicOrderView = () => {
      return (
        <>
          <Flex paddingLeft={4} paddingTop={4}>
            <Center flex={1}>
                <Input
                    color={'white'}
                    type="text"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onInput={onFilterTextBoxChanged}>
                  </Input>
                {/* <DrawerCreateNewOrder marginLeft={6} /> */}
                <Button minW={120} marginLeft={6} marginRight={6} colorScheme={'whiteAlpha'} onClick={() => {refreshData()}}> Refresh Data</Button>
            </Center>
          </Flex>
          <Flex p={4} >
            <div className="ag-theme-alpine" style={{height: "70vh", width: "97vw"}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
          </Flex>
        </>
      )
    }

    const KioskOrderView = () => {
      return (
        <>
          <Flex p={4}>
              <Box flex={5} p={2}>
                <Flex flexDir={'column'} w={'100%'}>
                    <Center>
                      <Text fontSize={'2xl'} fontWeight={'bold'} color={'white'}>Incoming Orders</Text>
                    </Center>
                    <Box backgroundColor={'rgba(255, 255, 255, .60)'} borderRadius={20} h={'70vh'} w={'100%'} p={4} overflow="auto">
                      {fakeArray.map((item, i )=> 
                        <Accordion allowToggle>
                          <AccordionItem>
                          <AccordionButton>
                            <Flex w={"100%"}>
                              <Center flex={1}>
                                <Box backgroundColor={'green.400'} w={20} h={10} borderRadius={20}></Box>
                              </Center>
                              <Box flex={1}>
                                <Flex flexDir={'column'}>
                                  <Text fontSize={'xs'} >Customer</Text>
                                  <Text fontSize={'xl'} fontWeight={'bold'}>Kate D.</Text>
                                </Flex>
                              </Box>
                              <Box flex={1}>
                                <Flex flexDir={'column'}>
                                  <Text fontSize={'xs'} >Order #</Text>
                                  <Text fontSize={'xl'} fontWeight={'bold'}>#8437598</Text>
                                </Flex>
                              </Box>
                              <Box flex={1}>
                                <Flex flexDir={'column'}>
                                  <Text fontSize={'xs'} >Total</Text>
                                  <Text fontSize={'xl'} fontWeight={'bold'}>$120.00</Text>
                                </Flex>
                              </Box>
                              <Box flex={1}>
                                <Flex flexDir={'column'}>
                                  <Text fontSize={'xs'} >Quantity</Text>
                                  <Text fontSize={'xl'} fontWeight={'bold'}>4 Items</Text>
                                </Flex>
                              </Box>
                              <Box flex={1}>
                                <Flex flexDir={'column'}>
                                  <Text fontSize={'xs'} >Submitted</Text>
                                  <Text fontSize={'xl'} fontWeight={'bold'}>6 minutes ago</Text>
                                </Flex>
                              </Box>
                            </Flex>
                            <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel >
                              <Flex flexDir={'column'} >
                                <Box flex={1}>
                                  <TableContainer p={2}>
                                    <Table size='sm'>
                                      <Thead>
                                        <Tr>
                                          <Th>in stock</Th>
                                          <Th>quantity</Th>
                                          <Th>product name</Th>
                                          <Th>category</Th>
                                          <Th>productId</Th>
                                          <Th>price</Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        <Tr>
                                          <Td><Box backgroundColor={'green.400'} w={12} h={6} borderRadius={20}></Box></Td>
                                          <Td><Center>1</Center></Td>
                                          <Td>Dr.Greenthumb Blue Dream</Td>
                                          <Td>Flower</Td>
                                          <Td>#8947389</Td>
                                          <Td>$40.00</Td>
                                        </Tr>
                                        <Tr>
                                          <Td><Box backgroundColor={'green.400'} w={12} h={6} borderRadius={20}></Box></Td>
                                          <Td><Center>1</Center></Td>
                                          <Td>Cresco Gummy - Sativa</Td>
                                          <Td>Edibles</Td>
                                          <Td>#5466354</Td>
                                          <Td>$30.00</Td>
                                        </Tr>
                                        <Tr>
                                          <Td><Box backgroundColor={'green.400'} w={12} h={6} borderRadius={20}></Box></Td>
                                          <Td><Center>1</Center></Td>
                                          <Td>Dr.Greenthumb Blue Dream</Td>
                                          <Td>Flower</Td>
                                          <Td>#8947389</Td>
                                          <Td>$40.00</Td>
                                        </Tr>
                                        <Tr>
                                          <Td><Box backgroundColor={'green.400'} w={12} h={6} borderRadius={20}></Box></Td>
                                          <Td><Center>1</Center></Td>
                                          <Td>Dr.Greenthumb Blue Dream</Td>
                                          <Td>Flower</Td>
                                          <Td>#8947389</Td>
                                          <Td>$40.00</Td>
                                        </Tr>
                                      </Tbody>  
                                    </Table>
                                  </TableContainer>
                                </Box>
                                <Box flex={1}>
                                  <Flex >
                                    <Button flex={1} isFullWidth backgroundColor={'blue.400'} m={2} borderRadius={20}>in progress</Button>
                                    <Button flex={1} isFullWidth backgroundColor={'green.400'} m={2} borderRadius={20}>prepared</Button>
                                    <Button flex={1} isFullWidth backgroundColor={'orange.400'} m={2} borderRadius={20}>delayed</Button>
                                  </Flex>
                                </Box>
                              </Flex>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      )}
                    </Box>
                </Flex>
              </Box>
              <Box flex={3} p={2}>
                <Flex flexDir={'column'} w={'100%'}>
                    <Center>
                      <Text fontSize={'2xl'} fontWeight={'bold'} color={'white'}>Orders Ready For Checkout</Text>
                    </Center>
                    <Box backgroundColor={'rgba(255, 255, 255, .60)'} borderRadius={20} h={'70vh'} w={'100%'} p={4} overflow="auto">
                    {fakeArray.map((item, i )=> 
                        <Accordion allowToggle>
                          <AccordionItem>
                          <AccordionButton>
                            <Flex w={"100%"}>
                              <Center flex={1}>
                                <Center backgroundColor={'green.400'} w={40} h={10} borderRadius={20} p={1}>
                                  6 min ago
                                </Center>
                              </Center>
                              <Box flex={1}>
                                <Flex flexDir={'column'}>
                                  <Text fontSize={'xs'} >Customer</Text>
                                  <Text fontSize={'xl'} fontWeight={'bold'}>Kate D.</Text>
                                </Flex>
                              </Box>
                              <Box flex={1}>
                                <Flex flexDir={'column'}>
                                  <Text fontSize={'xs'} >Total</Text>
                                  <Text fontSize={'xl'} fontWeight={'bold'}>$120.00</Text>
                                </Flex>
                              </Box>
                              <Box flex={1}>
                                <Flex flexDir={'column'}>
                                  <Text fontSize={'xs'} >Quantity</Text>
                                  <Text fontSize={'xl'} fontWeight={'bold'}>4 Items</Text>
                                </Flex>
                              </Box>
                              
                            </Flex>
                            <AccordionIcon />
                            </AccordionButton>
                            <AccordionPanel >
                              <Flex flexDir={'column'}>
                                <Box flex={1}>
                                  <TableContainer>
                                    <Table size='sm'>
                                      <Thead>
                                        <Tr>
                                          <Th>in stock</Th>
                                          <Th>product name</Th>
                                          <Th>catgeory</Th>
                                          <Th>price</Th>
                                        </Tr>
                                      </Thead>
                                      <Tbody>
                                        <Tr>
                                          <Td><Center backgroundColor={'green.400'} w={12} h={6} borderRadius={20}>1</Center></Td>
                                          <Td>Dr.Greenthumb Blue Dream</Td>
                                          <Td>Flower</Td>
                                          <Td>$40.00</Td>
                                        </Tr>
                                        <Tr>
                                          <Td><Center backgroundColor={'green.400'} w={12} h={6} borderRadius={20}>1</Center></Td>
                                          <Td>Cresco Gummy - Sativa</Td>
                                          <Td>Edibles</Td>
                                          <Td>$30.00</Td>
                                        </Tr>
                                        <Tr>
                                          <Td><Center backgroundColor={'green.400'} w={12} h={6} borderRadius={20}>1</Center></Td>
                                          <Td>Dr.Greenthumb Blue Dream</Td>
                                          <Td>Flower</Td>
                                          <Td>$40.00</Td>
                                        </Tr>
                                        <Tr>
                                          <Td><Center backgroundColor={'green.400'} w={12} h={6} borderRadius={20}>1</Center></Td>
                                          <Td>Dr.Greenthumb Blue Dream</Td>
                                          <Td>Flower</Td>
                                          <Td>$40.00</Td>
                                        </Tr>
                                      </Tbody>  
                                    </Table>
                                  </TableContainer>
                                </Box>
                                <Box flex={1}>
                                  <Flex>
                                    <Button flex={1} isFullWidth backgroundColor={'orange.400'} m={2} borderRadius={20}>cancel</Button>
                                    <Button flex={1} isFullWidth backgroundColor={'blue.400'} m={2} borderRadius={20}>complete</Button>
                                  </Flex>
                                </Box>
                              </Flex>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      )}
                    </Box>
                </Flex>
              </Box>
          </Flex>
        </>
      )
    }

    return (
      <>
        <Flex paddingLeft={4}>
          <Box flex={1}>
            <Flex>
              <Center>
                <Heading color={'white'} marginRight={6} >Orders</Heading>
              </Center>
              <Flex flexDir={'column'}>
                <Center>
                  <Text color={'white'}> Viewing Mode</Text>
                </Center>
                <Flex>
                  <Box >
                    <Button onClick={handleViewingModeChange} colorScheme={'whiteAlpha'} variant={viewingMode === 'kiosk' ? null : 'outline'} borderRadius={20} p={2} paddingRight={"12px"} paddingLeft={"12px"} marginRight={"12px"}>Kiosk</Button>
                  </Box>
                  <Box >
                    <Button onClick={handleViewingModeChange} colorScheme={'whiteAlpha'} variant={viewingMode === 'kiosk' ? 'outline' : null}  borderRadius={20} p={2} paddingRight={"12px"} paddingLeft={"12px"}>Overview</Button>
                  </Box>
                </Flex>
              </Flex>
              <Spacer />
              {viewingMode === 'overview' ? null : 
              <Flex flexDir={'column'} marginRight={20}>
                <Center>
                  <Text color={'white'}> Order Type</Text>
                </Center>
                <Flex>
                  <Box >
                    <Button colorScheme={'whiteAlpha'} borderRadius={20} p={2} paddingRight={"12px"} paddingLeft={"12px"} marginRight={"12px"}>In Store</Button>
                  </Box>
                  <Box >
                    <Button colorScheme={'whiteAlpha'} variant={'outline'} borderRadius={20} p={2} paddingRight={"12px"} paddingLeft={"12px"}>Delivery</Button>
                  </Box>
                </Flex>
              </Flex>
              
              }
            </Flex>
          </Box>
        </Flex>
        {viewingMode === 'kiosk' ? <KioskOrderView /> : <ClassicOrderView />}
      
        
      </>
    
    );
  }


export default Orders;