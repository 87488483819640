import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
// import {OBJModel} from 'react-3d-viewer'
import { useDisclosure, useToast, Flex, Box, Container, Text, Center, Heading, Spacer, Button, Image, Input, Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerFooter, Stack, FormLabel, InputGroup, InputLeftAddon, InputRightAddon, Select, Textarea, FormControl} from '@chakra-ui/react';

const updateProductInDatabase = (data) => {
  // const data = {
  //     "productId": "2d832159-58a3",
  //     "storeId": "12019922",
  //     "updatedKey": "brand",
  //     "updatedValue": "1-11"
  // };

  fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/updateProduct', {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({body: data}),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Success:', data);
  })
  .catch((error) => {
    console.error('Error:', error);
  });
}


function Customers() {
  const gridRef = useRef();
    const [rowData, setUsers] = useState([])
    const [columnDefs] = useState([
        { field: "orderId", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})} },
        { field: "storeId", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "productName", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "productType", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "pricePerUnit", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "quantity", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "strainType", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
        { field: "units", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
    ]);  
    //  updateProduct({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})

    // const handleViewingModeChange = () => {
    //   viewingMode === 'kiosk' ? (setViewingMode('overview')) : (setViewingMode('kiosk'))
    // }

    const refreshData = () => {
      const result = fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/getUsers', {mode: 'cors', headers: {'accessKey': '71F21618116CCB71DYZDA7517863A'}})
      .then(response => response.json())
      .then(data => {
        console.log('success', data)
          // console.log('in fetchInventory array data check', Array.isArray(data));
         setUsers(data);
        }
      ).catch(err => console.log('catch error!!!', err))
    }

    useEffect( () => {
      refreshData()
    }, []);
  
    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current.api.setQuickFilter(
        document.getElementById('filter-text-box').value
      );
    }, []);
    
    const fakeArray = [1,2,3,4,5, 6, 7, 8]

    console.log("orders", rowData)

    const ClassicView = () => {
      return (
        <>
          <Flex paddingLeft={4} paddingTop={4}>
            <Center flex={1}>
              <Heading color={'white'} marginRight={6} >Customers</Heading>
                <Input
                    color={'white'}
                    type="text"
                    id="filter-text-box"
                    placeholder="Filter..."
                    onInput={onFilterTextBoxChanged}>
                  </Input>
                {/* <DrawerCreateNewOrder marginLeft={6} /> */}
                <Button minW={120} marginLeft={6} marginRight={6} colorScheme={'whiteAlpha'} onClick={() => {refreshData()}}> Refresh Data</Button>
            </Center>
          </Flex>
          <Flex p={4} >
            <div className="ag-theme-alpine" style={{height: "80vh", width: "97vw"}}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}>
                </AgGridReact>
            </div>
          </Flex>
        </>
      )
    }

  return (
    <>
      <ClassicView />    
    </>
  );
}

export default Customers;
