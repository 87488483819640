import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
// import {OBJModel} from 'react-3d-viewer'
import { useDisclosure, useToast, Flex, Box, Container, Text, Center, Heading, Spacer, Button, Image, Input, Drawer, DrawerBody, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerFooter, Stack, FormLabel, InputGroup, InputLeftAddon, InputRightAddon, Select, Textarea, FormControl} from '@chakra-ui/react';


import get from 'lodash/get'

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';


const updateProductInDatabase = (data) => {
  // const data = {
  //     "productId": "2d832159-58a3",
  //     "storeId": "12019922",
  //     "updatedKey": "brand",
  //     "updatedValue": "1-11"
  // };

  fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/updateProduct', {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({body: data}),
  })
  .then(response => response.json())
  .then(data => {
    console.log('Success:', data);
  })
  .catch((error) => {
    console.error('Error:', error);
  });
}

const DrawerAddNewProduct = () => {
  const [formData, setFormData] = useState({category: 'flower'})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstField = React.useRef()
  const toast = useToast()


  const handleFormChange = (e, field) => {
    console.log("BITCH", field, e.target.value)
    let newValue = {}
    newValue[field] = e.target.value
    setFormData(formData => ({
      ...formData, 
      ...newValue
    }))
  }

  const createProductInDatabase = (formData) => {
      let formattedData = {
        "storeId": "newStoreNYC",
        "productName": formData.productName, 
        "category": formData.category, 
        "productType": formData.productType, 
        "strainType": formData.strainType,
        "brand": formData.brand, 
        "pricePerUnit": formData.pricePerUnit, 
        "units": formData.units, 
        "cannabinoids": {"Total Cannabinoids": formData.totalCannabinoids, "THC": formData.thc, "CBD": formData.cbd, "CBG": formData.cbg, "THCV": formData.thcv},
        "terpenes": {"Total Terpenes": formData.totalTerpenes, "B-Myrcene": formData.bMyrcene, "Limonene": formData.limonene, "B-Caryophyllene": formData.bCaryophyllene, "A-Humulene": formData.aHumulene}, 
        "rating": {"level": 0, "totalRatings": 0}, 
        "sale": false,
        "productAttributes": {
          "description": formData.description,
          "aroma": [formData.aroma],
          "productImage": formData.productImage
        }
    
    }
    
      fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/createProduct', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({body: formattedData}),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        toast({
          title: `${formData.productName} created.`,
          description: "Successfully saved new product",
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      })
      .catch((error) => {
        console.error('Error:', error);
        toast({
          title: `${formData.productName} could not be saved.`,
          description: "Please check your internet connection and try again.",
          status: 'error',
          duration: 5000,
          position: 'top',
          isClosable: true,
        })
      });
    }

  const handleSubmit = () => {
    createProductInDatabase(formData)
    onClose()
  }

  return (
    <>
      <Button colorScheme={'whiteAlpha'} onClick={onOpen} minW={200} marginLeft={6}>
      {/* <Button leftIcon={<AddIcon />} colorScheme='teal' onClick={onOpen}> */}
        Add New Product
      </Button>
      <Drawer
        colorScheme={'blue'}
        size={'xl'}
        isOpen={isOpen}
        placement='right'
        initialFocusRef={firstField}
        onClose={onClose}
      >
        <DrawerOverlay />
          <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>
            Create New Product
          </DrawerHeader>
          {/* <FormControl> */}
            
          <DrawerBody>
            <Flex>
              <Box flex={1}/>
              <Box flex={10}>
                <Stack spacing='24px'>
                  <Box>
                    <FormLabel isRequired>Product Name</FormLabel>
                    <Input
                      isRequired
                      ref={firstField}
                      id='productName'
                      placeholder='Product Display Name (Public)'
                      onChange={(e) => handleFormChange(e, 'productName')}
                      />
                  </Box>
                  <Box>
                    <FormLabel isRequired>Select Category</FormLabel>
                    <Select id='category' defaultValue='flower' onChange={(e) => handleFormChange(e, 'category')}>
                      <option value='flower'>Flower</option>
                      <option value='edibles'>Edible</option>
                      <option value='topical'>Topical</option>
                      <option value='concentrate'>Concentrate</option>
                      <option value='accessory'>Accessory</option>
                    </Select>
                  </Box>
                  <Box>
                    <FormLabel >Product Type</FormLabel>
                    <Input
                      id='productType'
                      placeholder='Product Type ex. Whole Flower'
                      onChange={(e) => handleFormChange(e, 'productType')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Strain Type</FormLabel>
                    <Input
                      id='strainType'
                      placeholder='Strain Type ex. Sativa'
                      onChange={(e) => handleFormChange(e, 'strainType')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Brand</FormLabel>
                    <Input
                      id='brand'
                      placeholder='Brand ex. Cresco'
                      onChange={(e) => handleFormChange(e, 'brand')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Price Per Unit</FormLabel>
                    <Input
                      id='pricePerUnit'
                      placeholder='Price Per Unit ex. 45.00'
                      onChange={(e) => handleFormChange(e, 'pricePerUnit')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Units</FormLabel>
                    <Input
                      id='units'
                      placeholder='Units ex. 3.5g'
                      onChange={(e) => handleFormChange(e, 'units')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Cannabinoids: Total Cannabinoids</FormLabel>
                    <Input
                      id='Total Cannabinoids'
                      placeholder="29.71%"
                      onChange={(e) => handleFormChange(e, 'totalCannabinoids')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Cannabinoids: THC</FormLabel>
                    <Input
                      id='THC'
                      placeholder="27.72%"
                      onChange={(e) => handleFormChange(e, 'thc')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Cannabinoids: CBD</FormLabel>
                    <Input
                      id='CBD'
                      placeholder="0.10%"
                      onChange={(e) => handleFormChange(e, 'cbd')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Cannabinoids: CBG</FormLabel>
                    <Input
                      id='CBG'
                      placeholder="1.03%"
                      onChange={(e) => handleFormChange(e, 'cbg')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Cannabinoids: THCV</FormLabel>
                    <Input
                      id='THCV'
                      placeholder="1.03%"
                      onChange={(e) => handleFormChange(e, 'thcv')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Terpenes: Total Terpenes</FormLabel>
                    <Input
                      id='Total Terpenes'
                      placeholder="3.7%"
                      onChange={(e) => handleFormChange(e, 'totalTerpenes')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Terpenes: B-Myrcene</FormLabel>
                    <Input
                      id='B-Myrcene'
                      placeholder="0.85%"
                      onChange={(e) => handleFormChange(e, 'bMyrcene')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Terpenes: Limonene</FormLabel>
                    <Input
                      id='Limonene'
                      placeholder="3.00%"
                      onChange={(e) => handleFormChange(e, 'limonene')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Terpenes: B-Caryophyllene</FormLabel>
                    <Input
                      id='B-Caryophyllene'
                      placeholder="0.64%"
                      onChange={(e) => handleFormChange(e, 'bCaryophyllene')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Terpenes: A-Humulene</FormLabel>
                    <Input
                      id='A-Humulene'
                      placeholder="0.33%"
                      onChange={(e) => handleFormChange(e, 'aHumulene')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Aroma</FormLabel>
                    <Input
                      id='Aroma'
                      placeholder="lemon, diesel, berry, earthy"
                      onChange={(e) => handleFormChange(e, 'aroma')}
                      />
                  </Box>
                  <Box>
                    <FormLabel >Product Image Link</FormLabel>
                    <Input
                      id='productImage'
                      placeholder="https://flowerly.s3.amazonaws.com/product-content/{STORE}/{PRODUCT-NAME}.webp"
                      onChange={(e) => handleFormChange(e, 'productImage')}
                      />
                  </Box>
                  <Box>
                    <FormLabel htmlFor='desc'>Description</FormLabel>
                    <Textarea id='desc' onChange={(e) => handleFormChange(e, 'description')}/>
                  </Box>
                </Stack>

              </Box>
              
            <Box flex={1}/>
            </Flex>
          </DrawerBody>
        {/* </FormControl> */}

          <DrawerFooter borderTopWidth='1px'>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme={'gray'} isFullWidth type={'submit'} onClick={handleSubmit}>Submit</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

const Products = function() {
  const gridRef = useRef();
    const [rowData, setInventory] = useState([])
    const [columnDefs] = useState([
      { field: "brand", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})} },
      { field: "category", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
      { field: "productName", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
      { field: "productType", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
      { field: "pricePerUnit", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
      { field: "quantity", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
      { field: "strainType", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
      { field: "units", sortable: true, filter: true, editable: true, onCellValueChanged: (params) => { updateProductInDatabase({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})}  },
  ]);  
  //  updateProduct({productId: params.data.productId, storeId: params.data.storeId, updatedKey: params.column.colId, updatedValue: params.newValue})

      const refreshData = () => {
        const result = fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/getInventory')
        .then(response => response.json())
        .then(data => {
            // console.log('in fetchInventory array data check', Array.isArray(data));
            setInventory(data);
          }
        ).catch(err => console.log('catch error!!!', err))
      }
  
      useEffect( () => {
        refreshData()
      }, []);
    
      const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
          document.getElementById('filter-text-box').value
        );
      }, []);
    
  

    console.log("inventory", rowData)

    return (
      <>
        <Flex paddingLeft={4}>
          <Center flex={11}>
            <Heading color={'white'} marginRight={6} >Products</Heading>
            
          
            
              <Input
                  
                  color={'white'}
                  type="text"
                  id="filter-text-box"
                  placeholder="Filter..."
                  onInput={onFilterTextBoxChanged}>
                </Input>
                
              <DrawerAddNewProduct marginLeft={6} />
              <Button minW={120} marginLeft={6} colorScheme={'whiteAlpha'} onClick={() => {refreshData()}}> Refresh Data</Button>

          </Center>
          <Box flex={1}></Box>
          
          
         
        </Flex>
        <Flex p={4} >
          <div className="ag-theme-alpine" style={{height: "80vh", width: "97vw"}}>
              <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}>
              </AgGridReact>
          </div>
        </Flex>
        
      </>
    
    );
  }


export default Products;


  // import { Box } from 'victory';

// const createNewProduct = async function() {
//     try {
//         const result = await DataStore.save(
//             new Inventory({
//                 "Product": {
//                     "name": "Test NAme",
//                     "store_id": "123456",
//                     "published": false,
//                     "online_orderable": false,
//                     "product_id": "jskdhflh-324584055",
//                     "variants": {},
//                     "categories": {},
//                     "attributes": {}
//                 },
//                 "product_id": "Lorem ipsum dolor sit amet",
//                 "stores": []
//             })
//         );
//         console.log("Product saved successfully!", result);
//       } catch (error) {
//         console.log("Error saving post", error);
//       }
 
// }


// const deleteInventory = async function(inventoryId) {
//     try {
//         const modelToDelete = await DataStore.query(Inventory, inventoryId);
//         console.log("Almost Deleted");
//         return DataStore.delete(modelToDelete);
//       } catch (error) {
//         console.log("Error deleting product", error);
//       }
 
// }

// const FileUploader = () => {
//     const handleFileInput = () => {}

//     return (
//         <div className="file-uploader">
//             <input type="file" onChange={handleFileInput} />
//         </div>
//     )
// }

// const FileHandler = async () => {
//     const [name, setName] = useState("");
//     const [selectedFile, setSelectedFile] = useState(null);

//     return (
//         <div className="fileHandler">
//           <form>
//             <input
//               type="text"
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//             />
    
//             <input
//               type="file"
//               value={selectedFile}
//               onChange={(e) => setSelectedFile(e.target.files[0])}
//             />
//           </form>
//         </div>
//       );
// }

// const ProductList = function(props) {

//     if (props.inventory !== null) {
//         let listItems = props.inventory.map((product, i) =>
//                 <tr key={i}>
//                 <td><Button variant="link" style={{fontWeight: "bold"}}>{product.productId}</Button></td>
//                 {/* <td colSpan={1}><OBJModel width={50} height={50} src="https://flowerly.s3.amazonaws.com/product-content/OBJ-test/textured.obj" texPath="flower-3d"/></td> */}
//                 <td >{product.productName}</td>
//                 <td>{product.brand}</td>
//                 <td>{product.category}</td>
//                 <td>{product.strainType}</td>
//                 <td>{product.productType}</td>
//                 <td>{product.pricePerUnit}</td>
//                 <td>{product.units}</td>
//                 </tr>
//             );
//             return (
//                 <tbody>
//                     {listItems} 
//                 </tbody>
            
//         );
//     } 

    
//   }